.el-dialog--center .el-dialog__body {
  padding: 20px;
}
.upload .uploadvideo {
  display: flex;
}
.upload .uploadvideo .el-upload {
  height: auto !important;
  border: none;
}
.upload .el-button {
  height: auto !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  font-size: 14px;
}
.active {
  background-color: #d7d7d7;
}
.data_box {
  height: 500px;
}
