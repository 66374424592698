.table_box table {
  width: 100%;
  max-width: 100%;
}
.table_box table td {
  max-width: 110px;
}
.table_box .el-input__icon.el-icon-date {
  position: absolute;
}
.table_box .el-icon-date:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
