















































































































































































































































































































































































































































































































































































































.table_box {
  table {
    width: 100%;
    max-width: 100%;
    td {
      max-width: 110px;
    }
  }
  .el-input__icon.el-icon-date {
    position: absolute;
  }
  .el-icon-date:before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
