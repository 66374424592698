













































































































































































































































































































.upload {
  .uploadvideo {
    display: flex;
    .el-upload {
      height: auto !important;
      border: none;
    }
  }
  .el-button {
    height: auto !important;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  font-size: 14px;
}
.active {
  background-color: #d7d7d7;
}
